<div class="border rounded bg-white p-4 text-sm">
    <h6 class="font-medium text-gray-600" *ngIf="title">{{ title }}</h6>
    <ul class="">
        <li>{{ address.fullName }}</li>
        <li>{{ address.streetLine1 }}</li>
        <li>{{ address.postalCode }}</li>
        <li>{{ getCountryName() }}</li>
    </ul>
    <ng-content></ng-content>
</div>
