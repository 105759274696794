<vsf-layout>
    <vsf-layout-header>
        <div class="bg-gradient-to-r from-zinc-600 to-zinc-800 shadow-lg transform shadow-xl">
            <div class="bg-zinc-100 text-gray-600 shadow-inner text-center text-sm py-2 px-2 xl:px-0" >
                <div class="max-w-6xl mx-2 md:mx-auto flex items-center justify-between" >
                    <div dir="rtl">
                        <p class="hidden sm:block">
                            Exclusive: Get your own
                            <a
                                class="underline"
                                href="https://github.com/vendure-ecommerce/storefront-angular-starter"
                                target="_blank"
                            >
                                FREE storefront starter kit
                            </a>
                        </p>
                    </div>
                    <div >
                        <vsf-account-link class=""></vsf-account-link>
                    </div>
                </div>
            </div>
            <div class="max-w-6xl mx-2 py-2 md:mx-auto flex items-center space-x-4" dir="rtl" >
                <vsf-mobile-menu-toggle class="md:hidden"></vsf-mobile-menu-toggle>
                <a [routerLink]="['/']" class="l" >
                    <img alt="" class="" width="110" src="assets/icons/logo.png">
                </a>
                <vsf-collections-menu class="hidden md:block text-white"></vsf-collections-menu>
                <vsf-product-search-bar class=""></vsf-product-search-bar>
                <vsf-cart-toggle (toggle)="openCartDrawer()"></vsf-cart-toggle>
            </div>
        </div>
    </vsf-layout-header>
    <vsf-collections-menu-mobile [visible]="mobileNavVisible$ | async" class="z-10"></vsf-collections-menu-mobile>
    <vsf-cart-drawer (close)="closeCartDrawer()" [visible]="cartDrawerVisible$ | async"></vsf-cart-drawer>
    <div class="contents">
        <vsf-home-page *ngIf="isHomePage$ | async; else main"></vsf-home-page>
        <ng-template #main>
            <router-outlet></router-outlet>
        </ng-template>
    </div>

    <vsf-layout-footer>
        <footer
            aria-labelledby="footer-heading"
            class="mt-24 border-t bg-gray-50"
            dir="rtl"
        >
            <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 ">
                <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div class="grid grid-cols-2 gap-8 xl:col-span-2">
                        <div class="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                                    חנות
                                </h3>
                                <ul class="mt-4 space-y-4" role="list">
                                    <li *ngFor="let item of topCollections$ | async">
                                        <a
                                            [routerLink]="['/category', item.slug]"
                                            class="text-base text-gray-500 hover:text-gray-600"
                                        >
                                            {{item.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="mt-12 md:mt-0">
                                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                                    תמיכה
                                </h3>
                                <ul class="mt-4 space-y-4" role="list">
                                    <li *ngFor="let item of navigation.support">
                                        <a
                                            [href]="item.href"
                                            class="text-base text-gray-500 hover:text-gray-600"
                                        >
                                            {{item.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                                    מוטו-הייטק
                                </h3>
                                <ul class="mt-4 space-y-4" role="list">
                                    <li *ngFor="let item of navigation.company">
                                        <a
                                            [href]="item.href"
                                            class="text-base text-gray-500 hover:text-gray-600"
                                        >
                                            {{item.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="mt-8 xl:mt-0">
                       
                        <img width="185" src="assets/icons/logo.png">

                        <p class="mt-4 text-base text-gray-500">
                            רחוב העבודה 67, א.תעשיה, אשדוד
                        </p>
                        <p class="mt-4 text-base text-gray-500">
                            טלפון: 08-8526034
                        </p>
                        <!-- <form class="mt-4 sm:flex sm:max-w-md">
                            <label class="sr-only" htmlFor="email-address">
                                Email address
                            </label>
                            <input
                                autoComplete="email"
                                class="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
                                id="email-address"
                                name="email-address"
                                placeholder="Enter your email"
                                required
                                type="email"
                            />
                            <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                                <button
                                    class="w-full bg-primary-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary-500"
                                    type="submit"
                                >
                                    Subscribe
                                </button>
                            </div>
                        </form> -->
                    </div>
                </div>

                <div class="mt-8 border-t pt-8" dir="ltr">

                    <span class="devop">
                        <span> 
                            <span>Powered by </span> 
                            <a class="end-line" target="_blank"
                            href="https://github.com/idanzam" >
                                <span>Idan Zamir-Halevy</span>
                                    
                            </a>
                            <span> 2024© |</span> 
                        </span>
<!-- 
                        <a class="end-line" target="_blank"
                           href="https://github.com/idanzam" >
                           Idan Zamir-Halevy              
                        </a> -->
                        <img width="85" src="assets/icons/angular.png">
                    </span>
                    
                </div>
            </div>
        </footer>
    </vsf-layout-footer>

</vsf-layout>
