
<div class="main-card">
    <div class="flex relative max-w-3xl mx-auto flex flex-col items-center  main-text">
        <div class="relative bg-zinc-800 bg-opacity-0 rounded-lg p-0">
            <!-- <h1 class="text-6xl text-transparent bg-clip-text font-extrabold tracking-normal lg:text-6xl bg-gradient-to-r from-white to-gray-200">
                Vendure <span class="text-[#aa2d2f] glow">מוטו-הייטק</span>
            </h1> -->
            <img src="assets/icons/logo.png"  width="350px"/>
        </div>
        <span class="font-extrabold text-4xl text-left w-full text">
                הבית של האופנועים
        </span>


        <!-- <div class="mt-4 text-4xl text-white">
            <p>
                <span class="font-extrabold text-6xl text-left">הבית של האופנועים</span>
            </p>
            <span class="font-extrabold text-6xl text-left">הבית של האופנועים</span>


            
        </div> -->
    </div>
</div>



<div class="relative">
    <!-- <img
            class="absolute inset-0 w-full object-cover h-full "
            src="assets/icons/main-pic.jpg"
            alt="header"
        /> -->
    <div
        aria-hidden="true"
        class="absolute inset-0 overflow-hidden"
    >
        <img
            class="absolute inset-0 w-full object-cover h-full "
            src="assets/icons/main-pic.jpg"
            alt="header"
        />
        <!--<div class="absolute inset-0 bg-gradient-to-br from-zinc-50 to-zinc-900 mix-blend-darken"></div>-->
    </div>
    <div
        aria-hidden="true"
        class="absolute inset-0 bg-gray-900 opacity-80"
    ></div>
    <!-- <div class="relative max-w-3xl mx-auto py-16 px-6 flex flex-col items-center text-center  sm:pb-72 lg:px-0">
        <div class="relative bg-zinc-800 bg-opacity-0 rounded-lg p-0">
            <h1 class="text-6xl text-transparent bg-clip-text font-extrabold tracking-normal lg:text-6xl bg-gradient-to-r from-white to-gray-200">
                Vendure <span class="text-[#aa2d2f] glow">Angular</span> Starter
            </h1>
        </div>

        <div class="mt-4 text-2xl text-white">
            <p>A headless commerce <span class="whitespace-nowrap">starter kit built with</span></p>

            <p>
                <a href="https://www.vendure.io" class="text-blue-300 hover:text-blue-500">Vendure</a>
                &
                <a href="https://angular.io/" class="text-red-700 hover:text-red-500">Angular</a>
            </p>
        </div>
    </div> -->
</div>
<div class="max-w-6xl mx-auto px-4 pt-12">
    <div class="box-content py-2 px-2 relative overflow-x-auto xl:overflow-visible">
        <div
            class="grid justify-items-center grid-cols-2 md:grid-cols-3 gap-y-8 gap-x-8 sm:px-6 lg:px-8 xl:relative xl:px-0 xl:space-x-0 xl:gap-x-8">
            <vsf-collection-card *ngFor="let collection of collections$ | async"
                                 [collection]="collection">
            </vsf-collection-card>
        </div>
    </div>
</div>
